<!-- @xjm 2022.10.3 v1.5.10 -->
<template>
  <div id="sign-wrapper">
    <div class="sign-item" v-if="signatories.length">
      <div class="sign-head flex-layout">
        <span class="sign-type">{{ signItem.type ? '接收方' : '发起方' }}</span>
        <div class="sign-opr">
          <span @click="moveUp" v-if="canUp">上移</span>
          <span @click="moveDown" v-if="canDown">下移</span>
          <span @click="delSign">删除</span>
        </div>
      </div>
      <div class="sign-form-item">
        <slot></slot>
      </div>
    </div>
    <div v-else>
      至少添加一位签署方
    </div>
  </div>
</template>
<script setup>
import { reactive, defineProps, defineEmit } from 'vue'
const props = defineProps(['signItem', 'signLen', 'signatories', 'canUp', 'canDown'])
const emits = defineEmit()
const signatoryList = reactive([])
// 上移
const moveUp = () => {
  let list = [...props.signatories]
  const index = props.signItem.serialNo
  list.splice(index - 1 - 1, 1, ...list.splice(index - 1, 1, list[index - 1 - 1]))
  list.forEach((item, idx) => {
    item.serialNo = idx + 1
  })
  emits('update:signatories', list)
}
// 下移
const moveDown = () => {
  // console.log('down')
  let list = [...props.signatories]
  const index = props.signItem.serialNo
  list.splice(index - 1, 1, ...list.splice(index, 1, list[index - 1]))
  list.forEach((item, idx) => {
    item.serialNo = idx + 1
  })
  emits('update:signatories', list)
}
// 删除
const delSign = () => {
  let list = [...props.signatories]
  const index = props.signItem.serialNo
  list.splice(index - 1, 1)
  list.forEach((item, idx) => {
    item.serialNo = idx + 1
  })
  emits('update:signatories', list)

  console.log(list)
}
</script>
<style lang="less" scoped>
#sign-wrapper {
  padding-top: 16px;
}
.sign-item {
  width: 100%;
  // height: 116px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #dcdee0;
}
.sign-head {
  height: 45px;
  background: #f5f6f7;
  justify-content: space-between;
  padding: 10px 24px;
}
.sign-opr {
  color: #1890ff;
  span {
    cursor: pointer;
  }
  span:first-child + span {
    padding: 0 24px;
  }
}
.sign-type {
  font-weight: 500;
  color: #131314;
}
.sign-form-item {
  padding: 0 24px;
}
</style>
